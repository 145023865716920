<template>
  <v-container fluid class="pa-0">

    <!-- App bar -->
    <v-app-bar height="30" dark color="primary" flat style="user-select: none">
      <div class="mx-auto" style="font-size: 20px">Mnemonist Software</div>
    </v-app-bar>

    <!-- Header -->
    <Header :title="$t('events.dates')" :memo="this.phase === 'recall' ? time : -1" ref="header"/>

    <!-- Pagination -->
    <Pagination :page="page" :pages="pages" :on-page-change="onPageChange"/>

    <!-- Game: memo -->
    <div id="memo" v-if="phase === 'memo'" class="d-flex flex-row flex-nowrap justify-center align-start">
      <div v-for="col in memoCols[page - 1]" :key="page + '-' + col.number"
           class="memo-col d-flex flex-column flex-nowrap justify-start align-center">
        <div v-for="date in col.dates" :key="date.id"
             class="memo-date d-flex flex-row flex-nowrap justify-center align-center"
             :style="{'background-color': highlight.includes(date.id) ? '#89bbee' : ''}"
             :class="{'highlight': highlight.includes(date.id)}">
          <div class="memo-date-date">{{ date.date }}</div>
          <div class="memo-date-event">
            {{ date.event }}
          </div>
        </div>
      </div>
    </div>

    <!-- Game: recall -->
    <div id="recall" v-if="phase === 'recall'" class="d-flex flex-row flex-nowrap justify-center align-start">
      <div v-for="col in recallCols[page - 1]" :key="page + '-' + col.number"
           class="recall-col d-flex flex-column flex-nowrap justify-start align-center">
        <div v-for="date in col.dates" :key="date.id"
             class="recall-date d-flex flex-row flex-nowrap justify-center align-center"
             :style="{'background-color': highlight.includes(date.id) ? '#89bbee' : ''}">
          <input type="text" class="recall-date-date-text-field" :id="'recall-input-' + date.id" maxlength="4"
                 v-model="recall[date.id]" @click="clickedRecallValue(date.id)"/>
          <div class="recall-date-event">{{ memo[order[date.id]][1] }}</div>
        </div>
      </div>
    </div>

    <!-- Game: result -->
    <div id="result" v-if="phase === 'result'" class="d-flex flex-row flex-nowrap justify-center align-start">
      <div v-for="col in recallCols[page - 1]" :key="page + '-' + col.number"
           class="result-col d-flex flex-column flex-nowrap justify-start align-center">
        <div v-for="date in col.dates" :key="date.id"
             @mouseover="resultHighlight = date.number" @mouseleave="resultHighlight = false"
             class="result-date d-flex flex-row flex-nowrap justify-center align-center">
          <input type="text" class="result-date-date-text-field" :id="'recall-input-' + date.id"
                 :value="date.number === resultHighlight ? memo[order[date.id]][0] : recall[date.id]"
                 :class="[resultCols[date.id].class]" readonly/>
          <div class="result-date-event">{{ memo[order[date.id]][1] }}</div>
        </div>
      </div>
    </div>

    <!-- Countdown -->
    <Countdown :title="$t('events.dates')" ref="countdown"/>

    <!--Mobile arrows-->
    <MobileArrows v-if="['memo', 'recall', 'result'].includes(phase) && $vuetify.breakpoint.width <= 1030"
                  :on-left="onLeft" :on-right="onRight"/>
    <div v-if="['memo', 'recall', 'result'].includes(phase) && $vuetify.breakpoint.width <= 1030"
         style="height: 60px; width: 100%"></div>

  </v-container>
</template>

<script>
import Header from '../components/HeaderTraining';
import Pagination from '../components/Pagination';
import Countdown from '../components/Countdown';
import MobileArrows from '../components/MobileArrows';

export default {
  name: 'TrainingDates',
  components: {Header, Pagination, Countdown, MobileArrows},
  data: () => ({
    page: 1, pages: 1, time: 0, score: -1, official: false, live: 0, phase: 'wait', highlight: [], input: 0,
    memo: [], recall: [], order: [], begin: 0,
    memoCols: [], recallCols: [], resultCols: [], resultHighlight: false, doubleClickMemoEnd: false
  }),
  computed: {
    params() {
      return this.$api.game.preferences;
    },
    liveTime() {
      if (this.phase === 'memo') return 0;
      else if (this.phase === 'result') return Math.min(this.time, this.params.memo * 1000);
      else return Math.max(-this.time, -this.params.memo * 1000);
    },
    onLeft() {
      if (this.phase === 'result' || this.params.highlight === 0) {
        return () => {
          if (this.page - 1 > 0) this.onPageChange(this.page - 1);
        };
      } else if (this.phase === 'memo') {
        return () => {
          let next = this.highlight[0] - this.params.highlight;
          if (next >= 0) {
            this.setHighlight(next);
            if (next % 24 >= 24 - this.params.highlight) this.onPageChange(this.page - 1, false);
          }
          setTimeout(() => document.querySelector('.highlight').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          }), 12);
        };
      } else if (this.phase === 'recall') {
        return () => {
          if (this.input === 0) return;
          this.input -= 1;
          if ((this.input + 1) % 24 === 0) {
            let inp = this.input;
            this.onPageChange(this.page - 1, false);
            setTimeout(() => {
              this.setHighlight(inp);
              let f = document.getElementById('recall-input-' + inp);
              f.focus();
              f.scrollIntoView({behavior: 'smooth', block: 'center'});
              this.input = inp;
            }, 20);
          } else {
            this.setHighlight(this.input);
            let f = document.getElementById('recall-input-' + (this.input))
            f.focus();
            f.scrollIntoView({behavior: 'smooth', block: 'center'});
          }
        };
      } else return () => ({});
    },
    onRight() {
      if (this.phase === 'result' || this.params.highlight === 0) {
        return () => {
          if (this.page + 1 <= this.pages) this.onPageChange(this.page + 1);
        };
      } else if (this.phase === 'memo') {
        return () => {
          if (this.doubleClickMemoEnd && this.highlight[0] + this.params.highlight >= this.params.number)
            this.$refs.header.reset();
          else this.doubleClickMemoEnd = this.highlight[0] + this.params.highlight >= this.params.number;
          let next = this.highlight[0] + this.params.highlight;
          if (next < this.params.number) {
            this.setHighlight(next);
            if (next % 24 < this.params.highlight) this.onPageChange(this.page + 1, false);
          }
          setTimeout(() => document.querySelector('.highlight').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          }), 12);
        };
      } else if (this.phase === 'recall') {
        return () => {
          if (this.input === this.params.number - 1) return;
          this.input += 1;
          if (this.input % 24 === 0) {
            let inp = this.input;
            this.onPageChange(this.page + 1, false);
            setTimeout(() => {
              this.setHighlight(inp);
              let f = document.getElementById('recall-input-' + inp)
              f.focus();
              f.scrollIntoView({behavior: 'smooth', block: 'center'});
              this.input = inp;
            }, 20);
          } else {
            this.setHighlight(this.input);
            let f = document.getElementById('recall-input-' + (this.input));
            f.focus();
            f.scrollIntoView({behavior: 'smooth', block: 'center'});
          }
        };
      } else return () => ({});
    }
  },
  watch: {
    highlight: {
      handler() {
        this.$api.liveSet(this.$api.game.gid, this.highlight, this.liveTime, this.recall);
      }, deep: true
    },
    recall: {
      handler() {
        this.$api.liveSet(this.$api.game.gid, this.highlight, this.liveTime, this.recall);
      }, deep: true
    },
    time: {
      handler() {
        this.$api.liveSet(this.$api.game.gid, this.highlight, this.liveTime, this.recall);
      }, deep: true
    }
  },
  mounted() {
    this.pages = Math.ceil(this.params.number / 24);
    this.time = this.$api.isGameReadOnly() ? this.$api.game.time : this.$api.isGameLiveNow() ? this.$api.game.time : 0;
    this.score = this.$api.isGameReadOnly() ? this.$api.game.score : this.$api.isGameLiveNow() ? this.$api.game.score : -1;
    this.official = this.$api.game.official;
    this.live = parseInt(this.$api.game.live);
    this.phase = this.$api.isGameReadOnly() ? 'result' : 'wait';
    for (let i = 0; i < this.params.highlight; i++) this.highlight.push(i);
    this.memo = this.$api.game.data.map(date => [date.split('.')[0], date.split('.')[1]]);
    this.order = this.$api.game.order;

    if (this.$api.isGameReadOnly() || this.$api.isGameLiveNow())
      this.recall = this.$api.game.recall;
    else for (let i = 0; i < this.params.number; i++) this.recall.push('');

    for (let i = 0; i < this.memo.length; i += 1) {
      if (i % 24 === 0) this.memoCols.push([]);
      if (i % 12 === 0) this.memoCols[this.memoCols.length - 1].push({number: Math.floor(i / 12) + 1, dates: []});
      this.memoCols[this.memoCols.length - 1][this.memoCols[this.memoCols.length - 1].length - 1].dates.push({
        id: i, date: this.memo[i][0], event: this.memo[i][1], number: i + 1
      });
    }

    for (let i = 0; i < this.memo.length; i += 1) {
      if (i % 24 === 0) this.recallCols.push([]);
      if (i % 12 === 0) this.recallCols[this.recallCols.length - 1].push({number: Math.floor(i / 12) + 1, dates: []});
      this.recallCols[this.recallCols.length - 1][this.recallCols[this.recallCols.length - 1].length - 1].dates.push({
        id: i, number: i + 1
      });
    }


    if (!this.$api.isGameReadOnly()) {
      if (this.$api.isGameLiveNow()) {
        let now = new Date().getTime();
        if (now < this.live) {
          this.$refs.countdown.start(new Date().getTime() + this.live - now, false, this.startMemo);
        } else if (now < this.live + this.params.memo * 1000 && this.time === 0) {
          this.startMemo(this.live + this.params.memo * 1000 - now);
          this.begin = this.live;
        } else if (now < this.live + this.params.memo * 1000 + 10000) {
          this.phase = 'wait';
          this.$refs.countdown.start(this.live + this.params.memo * 1000 + 10000, false, this.startRecall);
          this.time = Math.abs(this.time);
        } else if (now < this.live + 1000 * (this.params.memo + 10 + this.params.recall) && this.time < 0) {
          this.startRecall(this.live + this.params.memo * 1000 + 10000 + this.params.recall * 1000 - now);
          this.time = Math.abs(this.time);
        } else this.stopRecall();
      } else this.$refs.countdown.start(new Date().getTime() + 10000, true, this.startMemo);
    } else {
      let localRecall = new Array(this.params.number).fill('');
      for (let i = 0; i < this.params.number; i++)
        localRecall[i] = this.recall[this.order[i]];
      this.recall = localRecall;
      this.stopRecall();
    }

    /*if (!this.$api.isGameReadOnly()) {
      let delay = this.live === 0 ? 10000 : this.live - new Date().getTime();
      this.$refs.countdown.start(new Date().getTime() + delay, this.live === 0, this.startMemo);
    } else {
      let localRecall = new Array(this.params.number).fill('');
      for (let i = 0; i < this.params.number; i++)
        localRecall[i] = this.recall[this.order[i]];
      this.recall = localRecall;
      this.stopRecall();
    }*/

    document.addEventListener('keydown', this.eventListener);
  },
  beforeDestroy() {
    clearTimeout(this.$refs.countdown.countdown);
    clearTimeout(this.$refs.header.countdown);
    this.$api.game.gid = 'local';
    this.$api.submitGame();
    document.removeEventListener('keydown', this.eventListener);
  },
  methods: {
    clickedRecallValue(id) {
      this.input = id;
      this.setHighlight(this.input);
    },
    eventListener(e) {
      if (this.phase === 'memo') {
        if ((e.key === 'ArrowRight' || e.key === 'ArrowDown') && this.doubleClickMemoEnd && this.highlight[0] + this.params.highlight >= this.params.number)
          this.$refs.header.reset();
        else this.doubleClickMemoEnd = (e.key === 'ArrowRight' || e.key === 'ArrowDown') && this.highlight[0] + this.params.highlight >= this.params.number;

        if (e.key === 'ArrowLeft' || e.key === 'ArrowUp') {
          let next = this.highlight[0] - this.params.highlight;
          if (next >= 0) {
            this.setHighlight(next);
            if (next % 24 >= 24 - this.params.highlight) this.onPageChange(this.page - 1, false);
          }
        } else if (e.key === 'ArrowRight' || e.key === 'ArrowDown') {
          let next = this.highlight[0] + this.params.highlight;
          if (next < this.params.number) {
            this.setHighlight(next);
            if (next % 24 < this.params.highlight) this.onPageChange(this.page + 1, false);
          }
        } else if (e.key === 'Enter') {
          this.$refs.header.reset();
        }
      }

      if (this.phase === 'recall') {
        if (e.key === 'ArrowLeft') {
          if (this.input < 12) return;
          this.input -= 12;
          if (this.input % 24 >= 12) {
            let inp = this.input;
            this.onPageChange(this.page - 1, false);
            setTimeout(() => {
              this.setHighlight(inp);
              let f = document.getElementById('recall-input-' + inp)
              f.focus();
              this.input = inp;
            }, 20);
          } else {
            this.setHighlight(this.input);
            let f = document.getElementById('recall-input-' + (this.input));
            f.focus();
          }
        } else if (e.key === 'ArrowRight') {
          if (this.input >= this.params.number - 12) return;
          this.input += 12;
          if (this.input % 24 < 12) {
            let inp = this.input;
            this.onPageChange(this.page + 1, false);
            setTimeout(() => {
              this.setHighlight(inp);
              let f = document.getElementById('recall-input-' + inp)
              f.focus();
              this.input = inp;
            }, 20);
          } else {
            this.setHighlight(this.input);
            let f = document.getElementById('recall-input-' + (this.input));
            f.focus();
          }
        } else if (e.key === 'ArrowUp') {
          if (this.input === 0) return;
          this.input -= 1;
          if ((this.input + 1) % 24 === 0) {
            let inp = this.input;
            this.onPageChange(this.page - 1, false);
            setTimeout(() => {
              this.setHighlight(inp);
              let f = document.getElementById('recall-input-' + inp);
              f.focus();
              this.input = inp;
            }, 20);
          } else {
            this.setHighlight(this.input);
            let f = document.getElementById('recall-input-' + (this.input))
            f.focus();
          }
        } else if (e.key === 'ArrowDown') {
          if (this.input === this.params.number - 1) return;
          this.input += 1;
          if (this.input % 24 === 0) {
            let inp = this.input;
            this.onPageChange(this.page + 1, false);
            setTimeout(() => {
              this.setHighlight(inp);
              let f = document.getElementById('recall-input-' + inp)
              f.focus();
              this.input = inp;
            }, 20);
          } else {
            this.setHighlight(this.input);
            let f = document.getElementById('recall-input-' + (this.input));
            f.focus();
          }
        } else if (e.key === 'Enter' && this.input !== this.params.number - 1) {
          this.input += 1;
          if (this.input % 24 === 0) {
            let inp = this.input;
            this.onPageChange(this.page + 1, false);
            setTimeout(() => {
              this.setHighlight(inp);
              let f = document.getElementById('recall-input-' + inp)
              f.focus();
              this.input = inp;
            }, 20);
          } else {
            this.setHighlight(this.input);
            let f = document.getElementById('recall-input-' + (this.input));
            f.focus();
          }
        } else if (e.key === 'Enter') {
          this.$refs.header.reset();
        } else if (e.key === 'Tab') {
          e.preventDefault();
          if (this.input === this.params.number - 1) return;
          this.input += 1;
          if (this.input % 24 === 0) {
            let inp = this.input;
            this.onPageChange(this.page + 1, false);
            setTimeout(() => {
              this.setHighlight(inp);
              let f = document.getElementById('recall-input-' + inp)
              f.focus();
              this.input = inp;
            }, 20);
          } else {
            this.setHighlight(this.input);
            let f = document.getElementById('recall-input-' + (this.input));
            f.focus();
          }
        }
      }

      if (e.key === ',') {
        e.preventDefault();
        if (this.page - 1 >= 1) this.onPageChange(this.page - 1);
      } else if (e.key === '.') {
        e.preventDefault();
        if (this.page + 1 <= this.pages) this.onPageChange(this.page + 1);
      } else if (e.key === ' ' && ['memo', 'recall'].includes(this.phase)) {
        e.preventDefault();
        this.onPageChange(1);
      }
    },
    setHighlight(id) {
      if (this.highlight.includes(id)) return;
      let newHighlight = [];
      for (let i = 0; i < this.params.highlight; i++)
        newHighlight.push(this.params.highlight * Math.floor(id / this.params.highlight) + i);
      this.highlight = newHighlight;
    },
    startMemo(total = this.params.memo * 1000) {
      this.phase = 'memo';
      this.$refs.header.start(new Date().getTime() + total, true, this.stopMemo);
      this.begin = new Date().getTime();
    },
    startRecall(total = this.params.recall * 1000) {
      this.onPageChange(1);
      this.input = 0;
      this.setHighlight(0);
      this.phase = 'recall';
      this.$refs.header.start(new Date().getTime() + total, true, this.stopRecall);
    },
    stopMemo() {
      this.phase = 'wait';
      let delay = this.live === 0 ? 10000 : this.live + 10000 + this.params.memo * 1000 - new Date().getTime();
      this.$refs.countdown.start(new Date().getTime() + delay, this.live === 0, this.startRecall);
      this.time = Math.min(Math.round((new Date().getTime() - this.begin) / 10), this.params.memo * 100);
    },
    stopRecall() {
      this.phase = 'result';
      let score = 0;
      for (let i = 0; i < this.memo.length; i++) {
        if (this.recall[i] === this.memo[this.order[i]][0]) score++;
        if (this.recall[i] === this.memo[this.order[i]][0])
          this.resultCols.push({class: 'result-correct'});
        else if (this.recall[i] === '')
          this.resultCols.push({class: 'result-empty'});
        else this.resultCols.push({class: 'result-incorrect'});
      }
      this.score = score;

      this.$api.liveSet(this.$api.game.gid, this.highlight, this.liveTime, this.recall);

      this.$refs.header.setScore(this.score, Math.min(this.time, 100 * this.params.memo));
      if (!this.$api.isGameReadOnly()) {
        let apiRecall = new Array(this.params.number).fill('');
        for (let i = 0; i < this.order.length; i++)
          apiRecall[this.order[i]] = this.recall[i];
        this.$api.submitGame(this.time, apiRecall).then(() => {
          this.$store.commit('setMyResults', []);
          this.$api.init().then(() => {
            this.$store.commit('setProfile', this.$api.profile);
            let bestScore = this.$store.state.profile.events['dates'].score;
            let bestTime = this.$store.state.profile.events['dates'].time;
            if (this.official && this.score === bestScore && this.time === bestTime) this.$store.commit('showAdvancedDialog', {
              title: this.$t('dialogs.personal-best-title'), sections: [{
                title: this.$t('dialogs.personal-best-section-title'),
                icon: 'mdi-trophy-award',
                content: this.$t('dialogs.personal-best-section-content')
              }]
            });
          });
        });
      }
    },
    onPageChange(page, hard = true) {
      this.page = page;
      window.scrollTo({top: 0, behavior: 'auto'});
      setTimeout(() => {
        if (this.phase === 'memo') {
          if (hard) this.setHighlight((page - 1) * 24);
        } else if (this.phase === 'recall') {
          this.input = (page - 1) * 24;
          this.setHighlight(this.input);
          let f = document.getElementById('recall-input-' + (this.input));
          f.focus();
        }
      }, 10);
    }
  }
};
</script>

<style scoped>
#memo {
  margin: 0 auto;
  width: 1000px;
}

.memo-col {
  width: 500px;
}

.memo-date {
  height: 34px;
}

.memo-date-date {
  color: #336799;
  font-size: 22px;
  font-weight: 500;
  height: 34px;
  padding: 0 6px;
  text-align: right;
  width: 60px;
}

.memo-date-event {
  font-size: 16px;
  height: 34px;
  line-height: 16px;
  padding: 4px 6px;
  width: 440px;
}

#recall {
  margin: 0 auto;
  width: 1000px;
}

.recall-col {
  width: 500px;
}

.recall-date {
  height: 40px;
  width: 500px;
}

.recall-date-date-text-field {
  border-bottom: 2px solid #336799;
  font-size: 19px;
  height: 34px;
  margin-left: 8px;
  padding: 0 6px;
  text-align: center;
  width: 62px;
}

.recall-date-event {
  font-size: 16px;
  height: 34px;
  line-height: 16px;
  margin-right: 8px;
  padding: 4px 6px;
  width: 422px;
}

#result {
  margin: 0 auto;
  width: 1000px;
}

.result-col {
  width: 500px;
}

.result-date {
  height: 40px;
  width: 500px;
}

.result-date-date-text-field {
  border-bottom: 2px solid #336799;
  font-size: 19px;
  height: 34px;
  margin-left: 8px;
  padding: 0 6px;
  text-align: center;
  width: 62px;
}

.result-date-event {
  font-size: 16px;
  height: 34px;
  line-height: 16px;
  margin-right: 8px;
  padding: 4px 6px;
  width: 422px;
}

input:focus {
  outline: none;
}

.result-correct {
  background-color: #82e599;
}

.result-incorrect {
  background-color: #eb7f7e;
}

.result-empty {
  background-color: transparent;
}

.result-date:hover .result-incorrect, .result-date:hover .result-empty {
  background: repeating-linear-gradient(72deg, #e0d8d5, #82e599 8%);
}

@media screen and (max-width: 1030px) {
  #memo, #recall, #result {
    flex-direction: column !important;
    width: 100%;
  }

  .memo-col, .recall-col, .result-col {
    margin: 0 auto;
    width: 360px;
  }

  .memo-date, .recall-date, .result-date {
    width: 360px;
  }

  .memo-date-event {
    font-size: 14px;
    padding: 5px 1px;
    width: 300px;
  }

  .recall-date-event, .result-date-event {
    font-size: 14px;
    margin-right: 0;
    padding: 5px 1px;
    width: 298px;
  }
}
</style>
